<template>
  <div class="box">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="header-font">订单交易成功</span>
        <el-steps class="stepsSctive" align-center :active="active">
          <el-step description="我的购物车" icon="el-icon-s-help"></el-step>
          <el-step description="填写核对订单" icon="el-icon-s-help"></el-step>
          <el-step description="交易成功" icon="el-icon-s-help"></el-step>
        </el-steps>
      </div>
      <div class="box">
        <p>
          <img class="store-img" src="/img/success_icon.png" alt="" />
        </p>

        <p class="store-font">
          下单成功 感谢您的支持
        </p>
        <p class="order-font">
          您的订单号：<span class="order-font-no">{{
            order.orderNo
          }}</span>
          付款金额：<span class="order-font-money">{{ order.price }}元</span>
          支付方式：{{ order.payMethod }}
        </p>
        <p class="order-p">
          <el-button @click="jump" class="order-button">
            查看订单</el-button>
          <el-button @click="goHome" class="order-button">返回首页</el-button>
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "fillInTheOrder",
  computed: {
    ...mapState({
      orderDetailData: (state) => {
        return state.orderListDetail.orderDetailData;
      },
    }),
  },
  data() {
    return {
      active: 3,
      orderId: 0,
      order: {},
    };
  },
  mounted() {
    this.orderId = this.$route.query.orderId;
    var that = this;
    that.$store
      .dispatch("orderListDetail/getOrderDetail", { id: that.orderId })
      .then(() => {
        that.order = that.orderDetailData;
      });
  },
  methods: {
    jump() {
      this.$router.push({
        path: "/personalCenter/personalCenter",
        query: {
          value: 2,
        },
      });
    },
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-color: #f8fcff;
  p {
    text-align: center;
  }
  .box-card {
    width: 1200px; /* no */
    margin: 20px auto; /* no */
  }
  .clearfix {
    padding: 0 0 20px 0; /* no */
  }
  .header-font {
    font-size: 18px; /* no */
    color: red;
  }
  .stepsSctive {
    width: 370px; /* no */
    float: right;
    /deep/.is-finish {
      color: #2ec4cd;
    }
    /deep/.is-process {
      color: rgb(192 196 204);
    }
    /deep/.el-step__description {
      padding-top: 6px; /* no */
    }
  }
  .store-img {
    width: 64px; /* no */
    height: 64px; /* no */
    object-fit: cover;
    margin-top: 100px; /* no */
  }
  .store-font {
    font-size: 24px; /* no */
    margin: 30px 0 29px 0; /* no */
  }
  .order-font {
    font-size: 16px; /* no */
  }
  .order-font-no {
    color: #c91c25;
    font-size: 14px; /* no */
  }
  .order-font-money {
    color: #c91c25;
  }
  .order-p {
    margin: 50px 0 120px 0; /* no */
  }
  .order-button {
    margin-right: 30px; /* no */
    background-color: #c91c25;
    color: white;
    width: 160px; /* no */
    height: 48px; /* no */
    font-size: 18px; /* no */
  }
}
</style>
